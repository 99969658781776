import { AtomLink } from 'atoms/AtomLink'
import axios from 'axios'
import { BPSizes, Colors, StyledP } from 'commons/Theme'
import { Html } from 'components/App/Html'
import { UserLoginProvider } from 'contexts/UserLoginContext'
import {
  ColumnLeft,
  ColumnRight,
  StyledBodyMessage,
  StyledPage,
  StyledPageBody,
  StyledPageGrid,
  StyledPageSubtitle,
  StyledPageTitle,
} from 'pages-style/404.style'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ContainerStyle } from '../commons/Container'
import { A2ADataContextProvider } from '../contexts/A2ADataContext'
import { LayoutContextProvider } from '../contexts/LayoutContext'
import { MaintenanceSVGImage } from '../pages-style/maintenanceSVG'
import { removeTrailingSlashes } from 'commons/utils'

const endpointUrl = `${removeTrailingSlashes(
  process.env.A2A_DRUPAL_CASA_URL
)}/a2a-api/login-resource?_format=json`

const ManutenzioneHeader = styled.div`
  ${ContainerStyle};
  height: 56px;
  width: 100%;

  img {
    height: 28px;
  }

  display: flex;
  align-items: center;

  @media screen and (min-width: ${BPSizes.tablet}px) {
    img {
      height: 32px;
    }
  }

  @media screen and (min-width: ${BPSizes.desktop}px) {
    height: 80px;
    img {
      height: 40px;
    }
  }
`

const ServizioManutenzione = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [maintenanceMessage, setMaintenanceMessage] = useState<string | null>(
    null
  )
  const [showDefaultMessage, setShowDefaultMessage] = useState<boolean>(false)

  const defaultMessage = (
    <>
      <StyledPageSubtitle>Stiamo lavorando per te!</StyledPageSubtitle>
      <StyledP $color="grey100">
        La pagina che stai cercando è in manutenzione e tornerà presto
        disponibile.
      </StyledP>
    </>
  )

  useEffect(() => {
    const getMaintenanceMessage = () => {
      setIsLoading(true)
      axios
        .get(endpointUrl)
        .then((res) => {
          if (res.data && res.data?.maintenance?.page_body?.value) {
            setShowDefaultMessage(false)
            setMaintenanceMessage(res.data?.maintenance?.page_body?.value)
          } else {
            setShowDefaultMessage(true)
          }

          setIsLoading(false)
        })
        .catch(() => {
          setShowDefaultMessage(true)
          setIsLoading(false)
        })
    }

    getMaintenanceMessage()
  }, [])

  return (
    <A2ADataContextProvider>
      <UserLoginProvider>
        <LayoutContextProvider>
          <StyledPage
            className="std std-light"
            $backgroundColor={Colors.grey700}
          >
            <ManutenzioneHeader>
              <img
                src="/assets/images/manutenzione-logo.png"
                alt="servizio di maggior tutela logo"
              />
            </ManutenzioneHeader>
            <StyledPageBody>
              <StyledPageGrid cols={1} desktopCols={2} rowsGap="0">
                <ColumnLeft>
                  {!isLoading && (
                    <>
                      <StyledPageTitle>Ops!</StyledPageTitle>
                      {showDefaultMessage && <>{defaultMessage}</>}
                      {!showDefaultMessage && maintenanceMessage !== null && (
                        <StyledBodyMessage>
                          <Html>{maintenanceMessage}</Html>
                        </StyledBodyMessage>
                      )}
                      <AtomLink
                        label="Vai al sito"
                        to="https://maggiortutela.a2a.it/"
                        styleType="ghost"
                        color="white"
                      />
                    </>
                  )}
                </ColumnLeft>
                <ColumnRight>
                  <MaintenanceSVGImage />
                </ColumnRight>
              </StyledPageGrid>
            </StyledPageBody>
          </StyledPage>
        </LayoutContextProvider>
      </UserLoginProvider>
    </A2ADataContextProvider>
  )
}

export default ServizioManutenzione
